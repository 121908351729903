import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './Components/LoginForm';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SignUpPage from './Pages/SignUpPage';
import './Components/loginform.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [usertype, setUsertype] = useState('');

  return (
    <Router>
      <ToastContainer
        className='toast-position'
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
      <Routes>
        <Route
          path='/'
          element={
            <SignUpPage
              setIsAuthenticated={setIsAuthenticated}
              setUsertype={setUsertype}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
