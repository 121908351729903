import React from 'react';
import './loginform.css';
import {
  MainImmage,
  Whatsapp,
  Instagram,
  Facebook,
  Gmail,
  Telegram,
  Messanger,
  Viber,
  Line,
  GoogleBusiness,
  Wechat,
  InterCom,
  JivoChat,
  SMS,
} from '../Assets';
const Logos = () => {
  return (
    <div className='circle'>
      <img
        src={Whatsapp}
        alt='Logo1'
        className='circle-logo'
        style={{ width: '60px', height: '60px' }}
      />
      <img
        src={Instagram}
        alt='Logo2'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={Facebook}
        alt='Logo3'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={Gmail}
        alt='Logo4'
        className='circle-logo'
        style={{ width: '65px', height: '65px' }}
      />
      <img
        src={Messanger}
        alt='Logo5'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={Viber}
        alt='Logo6'
        className='circle-logo'
        style={{ width: '85px', height: '55px' }}
      />
      <img
        src={Telegram}
        alt='Logo7'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={Line}
        alt='Logo7'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={GoogleBusiness}
        alt='Logo8'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={Wechat}
        alt='Logo9'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={InterCom}
        alt='Logo9'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={JivoChat}
        alt='Logo9'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
      <img
        src={SMS}
        alt='Logo9'
        className='circle-logo'
        style={{ width: '45px', height: '45px' }}
      />
    </div>
  );
};

export default Logos;
