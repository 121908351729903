import React from 'react';
import { MainImmage } from '../Assets';
const AlendeiMainImage = () => {
  return (
    <div
      className='flex-1 flex items-center justify-center test mainImage'
      style={{ paddingTop: '25vh' }}
    >
      <img src={MainImmage} alt='Alendei' className='w-2/5 h-auto ' />
    </div>
  );
};

export default AlendeiMainImage;
