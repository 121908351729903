import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './loginform.css';
import AlendeiImage from './Alendei1.png';

import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

import { LiaIndustrySolid } from 'react-icons/lia';

import { BiSolidError } from 'react-icons/bi';
import {
  MdOutlineBusiness,
  MdOutlineWorkspaces,
  // MdOutlineIndustry,
  MdOutlineLockOpen,
  MdOutlineGroup,
  MdOutlineLock,
} from 'react-icons/md';
import { PiEyeClosedBold } from 'react-icons/pi';
import { RxEyeOpen } from 'react-icons/rx';
import { BsAlphabetUppercase } from 'react-icons/bs';

import 'react-phone-input-2/lib/style.css';
import { FaRegMehRollingEyes } from 'react-icons/fa';

import { FaRegEye } from 'react-icons/fa6';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import FormStep1 from './FormStep1';
import Logos from './Logos';
import AlendeiMainImage from './AlendeiMainImage';
const SignupForm = ({ setIsAuthenticated, setUsertype }) => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [password, setPassword] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');

  const [checkbox1, setCheckbox1] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [workspaceError, setWorkspaceError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [isTeamopen, setIsTeamOpen] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [continues, setContinues] = useState(false);
  const [industryError, setIndustryError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [formStep, setFormStep] = useState(1);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpError, setOtpError] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const industries = [
    'Ecommerce',
    'Education',
    'Automotive',
    'IT Services/telecom',
    'Real Estate',
    'SAAS/Apps',
    'GAMING',
    'Entertainment',
    'Finance and Banking',
    'Medical and Health',
    'Hotel and Lodging',
    'Beauty, Spa and Salon',
    'Clothing and Apparel',
    'Event Planning and Service',
    'Food and Grocery',
    'Professional Services',
    'Public Services',
    'Non-Profit',
    'Shopping and Retail',
    'Travel and Transportation',
    'Restaurant',
    'Other',
  ];
  const Teams = [
    'StartUp',
    'Small (100 - 200 Employees)',
    'Medium (200 - 1000 Employees)',
    'Large (1000+ Employees)',
  ];
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const inputRefs = useRef([]);

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[@#$]/.test(password),
    };
    return criteria;
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(false);
    setPasswordCriteria(validatePassword(value));
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const sendStep1Data = async () => {
    try {
      const body = {
        fullName,
        phoneNumber,
        emailAddress,
      };
      const response = await axios.post(
        'https://ramsettu.alendei.com/api/v1/AlendeiRegistration',
        body
      );
      setFormStep(2);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
      if (error.response.status === 409) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
      if (error.response.status === 429) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  };
  const sendStep2Data = async () => {
    const data = {
      otp,
      phoneNumber,
    };
    try {
      const otpResponse = await axios.post(
        'https://ramsettu.alendei.com/api/v1/OTPVerifcation',
        data
      );
      setFormStep(3);
    } catch (error) {
      // console.log(error.response.data);
      if (error.response.status === 400) {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
      if (error.response.status === 401) {
        setOtpError(true);
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      } else if (error.response.status === 404) {
        setOtpError(true);
        setOtpExpired(true);
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  };
  const sendStep3Data = async () => {
    try {
      setRedirect(true);
      const submitbody = {
        phoneNumber,
        companyName,
        workspaceName,
        selectedTeam,
        selectedIndustry,
        password,
      };
      const submittedData = await axios.post(
        'https://ramsettu.alendei.com/api/v1/RegisterUser',
        submitbody
      );
      setRedirect(true);
      setTimeout(() => {
        window.location.href = 'https://ocpomni.alendei.io/login';
      }, 3000);
      // window.location.href = 'https://ocpomni.alendei.io/login';
      // console.log(submittedData.data.message);
    } catch (error) {
      setRedirect(false);
      if (error.response.status === 404) {
        toast.error(error.response.data.error);
      }
      if (error.response.status === 422) {
        toast.error(error.response.data.error);
      }
      // console.log(error.response.data.error);
    }
  };
  const handleContinue = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
      let valid = true;
      let errors = [];

      if (!fullName) {
        setNameError(true);
        errors.push('full name');
        valid = false;
      } else {
        setNameError(false);
      }

      if (!phoneNumber) {
        setPhoneError(true);
        errors.push('phone number');
        valid = false;
      } else {
        setPhoneError(false);
      }

      if (!emailAddress) {
        setEmailError(true);
        errors.push('email address');
        valid = false;
      } else if (!validateEmail(emailAddress)) {
        setEmailError(true);
        errors.push('correct email address');
        valid = false;
      } else {
        setEmailError(false);
      }
      if (errors.length > 1) {
        toast.error('Please fill the required details.');
      } else {
        if (errors.length === 1) {
          const field = errors[0];
          toast.error(`Please enter your ${field}.`);
        }

        if (valid) {
          await sendStep1Data();
          // setFormStep(2);
        }
      }
    }
    // else if (formStep === 2) {
    //   if (resendOtp) {
    //     setResendOtp(false);
    //   } else {
    //     await sendStep2Data();
    //   }
    // }
    else if (formStep === 2) {
      let valid = true;
      let errors = [];

      if (!companyName) {
        setCompanyError(true);
        errors.push('company name');
        valid = false;
      } else {
        setCompanyError(false);
      }
      if (!workspaceName) {
        setWorkspaceError(true);
        errors.push('workspace name');
        valid = false;
      } else {
        setWorkspaceError(false);
      }
      if (!selectedTeam) {
        setTeamError(true);
        errors.push('team size');
        valid = false;
      } else {
        setTeamError(false);
      }
      if (!selectedIndustry) {
        setIndustryError(true);
        errors.push('industry');
        valid = false;
      } else {
        setIndustryError(false);
      }
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$])[A-Za-z\d@#$]{8,}$/;
      if (!password) {
        setPasswordError(true);
        errors.push('password');
        valid = false;
      } else if (!passwordRegex.test(password)) {
        setPasswordError(true);
        errors.push('valid password');
        valid = false;
      } else {
        setPasswordError(false);
      }
      if (errors.length > 1) {
        toast.error('Please fill the required details.');
      } else {
        if (errors.length === 1) {
          const field = errors[0];
          toast.error(`Please enter your ${field}.`);
        }

        if (valid) {
          await sendStep3Data();
          // console.log('Form submitted');
        }
      }
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (/^[0-9]$/.test(value) || value === '') {
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next input if not the last input and a digit is entered
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (e.key === 'Backspace' && index > 0 && !value) {
      // Move focus to previous input if backspace is pressed and current input is empty
      inputRefs.current[index - 1].focus();
    }
    setOtpError(false);
  };
  const handleOtpKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'Enter' && index === otp.length - 1) {
      e.preventDefault();
      handleContinue(e);
    }
  };
  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (/^\d+$/.test(paste) && paste.length === otp.length) {
      const newOtp = paste.split('');
      setOtp(newOtp);

      newOtp.forEach((digit, index) => {
        inputRefs.current[index].value = digit;
      });

      inputRefs.current[otp.length - 1].focus();
    }
  };
  const handlegoBack = () => {
    setFormStep(1);
    setFullName('');
    setPhoneNumber('');
    setEmailAddress('');
    setOtpError(false);
    setOtp(new Array(6).fill(''));
  };
  const handleResendOtp = async () => {
    try {
      setResendOtp(true);
      const body = {
        phoneNumber,
      };
      const resendOTP = await axios.post(
        'https://ramsettu.alendei.com/api/v1/resendOTP',
        body
      );

      setResendCount((prevCount) => prevCount + 1);
      setCountdown(10);
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setIsResendDisabled(true);
      setTimeout(() => {
        clearInterval(timer);
        setIsResendDisabled(false);
      }, 10000);
    } catch (error) {
      // console.log(error.response.data.error);
      toast.error(error.response.data.error);
    }
  };
  const handleSelect = (industry) => {
    setSelectedIndustry(industry);
    setIsOpen(false);
    setIndustryError(false);
  };
  const handleSelectTeam = (team) => {
    setSelectedTeam(team);
    setIsTeamOpen(false);
    setTeamError(false);
  };

  const dropdownRef = useRef(null);
  const dropdownRefTeam = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleClickOutsideTeam = (event) => {
    if (
      dropdownRefTeam.current &&
      !dropdownRefTeam.current.contains(event.target)
    ) {
      setIsTeamOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsideTeam);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideTeam);
    };
  }, []);

  return (
    <div
      className='relative h-screen flex'
      style={{ backgroundColor: '#e7f5ed' }}
    >
      <Logos />
      <AlendeiMainImage />

      <div className='flex-1 flex items-center justify-center formContainer'>
        <form
          className='bg-white pt-8 pb-8 rounded-lg shadow-md flex flex-col justify-start custom-border-radius overflow-y-auto pr-10 pl-10 relative form'
          onSubmit={handleContinue}
          style={{ width: '32vw', marginRight: '8vw' }}
        >
          <div className='flex justify-end'>
            <img
              src={AlendeiImage}
              alt='Alendei'
              className='h-20 w-30 sm: h-12 sm: w-auto'
            />
          </div>

          {/* WELCOME */}
          <div
            className='flex justify-start headertext'
            style={{ marginTop: '-32px' }}
          >
            <div className='w-full'>
              <h1 className='text-2xl mb-2 pb-0 text-left text-black font-poppins welcometext'>
                Welcome to
              </h1>
              <h1 className='text-3xl font-bold text-left mb-6 text-green-700 font-poppins AlendeiPlatforms'>
                Alendei Platforms
              </h1>
            </div>
          </div>
          {/* STEP 1 */}
          {formStep === 1 && (
            <FormStep1
              setFullName={setFullName}
              setNameError={setNameError}
              // handlePhoneNumber={handlePhoneNumber}
              setPhoneError={setPhoneError}
              setPhoneNumber={setPhoneNumber}
              setEmailAddress={setEmailAddress}
              setEmailError={setEmailError}
              setContinues={setContinues}
              setCheckbox1={setCheckbox1}
              continues={continues}
              checkbox1={checkbox1}
              nameError={nameError}
              fullName={fullName}
              phoneError={phoneError}
              PhoneInput={PhoneInput}
              phoneNumber={phoneNumber}
              emailError={emailError}
              emailAddress={emailAddress}
            />
          )}

          {/* STEP 2*/}

          {/* {formStep === 2 && (
            <>
              <div className='mb-4 flex justify-center items-center'>
                {otp.map((digit, index) => (
                  <React.Fragment key={index}>
                    <input
                      type='text'
                      maxLength='1'
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      onPaste={handlePaste}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className={`w-12 h-12 text-center mx-2 otp-input border rounded focus:outline-none ${
                        otpError
                          ? 'focus:border-red-500'
                          : 'focus:border-green-500'
                      } text-lg ${
                        otpError ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                  </React.Fragment>
                ))}
              </div>

              {formStep === 2 && (
                <div className='flex justify-end mb-3'>
                  <button
                    onClick={handleResendOtp}
                    disabled={isResendDisabled}
                    className={`resend-otp-button text-green-500 ${
                      isResendDisabled
                        ? 'opacity-50 text-gray resend-otp-button1'
                        : ''
                    }`}
                  >
                    {isResendDisabled
                      ? `Resend OTP ( ${countdown} )`
                      : 'Resend OTP'}
                  </button>
                </div>
              )}
            </>
          )} */}
          {/* STEP 3 */}
          {formStep === 2 && (
            <div
              className='custom-scrollbar'
              style={{
                maxHeight: '50vh',
                // overflowY: 'auto',
                paddingRight: '1rem',
              }}
            >
              {/* FINAL FIELDS FOR THIRD FORM */}
              {/*COMPANY NAME*/}
              <div className='mb-4 flex justify-start'>
                <div
                  className={`flex w-full relative items-center custom-input-container ${
                    companyError ? 'input-error' : ''
                  }`}
                >
                  <MdOutlineBusiness
                    className='text-black mr-2 ml-4 mt-1'
                    size={24}
                  />
                  <input
                    type='text'
                    id='CompanyName'
                    name='CompanyName'
                    placeholder='Enter Company Name'
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setCompanyError(false);
                    }}
                    className='block w-full py-2 px-3 custom-bottom-border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base bg-transparent text-black'
                    style={{
                      paddingTop: '0.9rem',
                      paddingBottom: '0.9rem',
                      lineHeight: '1.5',
                    }}
                  />
                </div>
              </div>
              {/*WORKSPACE NAME*/}
              <div className='mb-4 flex justify-start'>
                <div
                  className={`flex w-full relative items-center custom-input-container ${
                    workspaceError ? 'input-error' : ''
                  }`}
                >
                  <MdOutlineWorkspaces
                    className='text-black mr-2 ml-4 mt-1'
                    size={24}
                  />
                  <input
                    type='text'
                    id='WorkspaceName'
                    name='WorkspaceName'
                    placeholder='Enter Workspace Name'
                    value={workspaceName}
                    onChange={(e) => {
                      setWorkspaceName(e.target.value);
                      setWorkspaceError(false);
                    }}
                    className='block w-full py-2 px-3 custom-bottom-border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base bg-transparent text-black'
                    style={{
                      paddingTop: '0.9rem',
                      paddingBottom: '0.9rem',
                      lineHeight: '1.5',
                    }}
                  />
                </div>
              </div>
              {/* TEAM SIZE*/}
              <div className='mb-4 flex justify-start' ref={dropdownRefTeam}>
                <div
                  className={`w-full relative flex items-center custom-input-container industry-dropdown ${
                    isTeamopen ? 'open' : ''
                  } ${teamError ? 'input-error' : ''}${
                    isPasswordFocused ? 'glass-active1' : ''
                  }`}
                >
                  <MdOutlineGroup
                    className='text-black mr-2 ml-4 mt-1'
                    size={24}
                  />
                  <div
                    className={`block w-full py-2 px-3 custom-bottom-border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base bg-transparent text-black cursor-pointer ${
                      selectedTeam ? '' : 'placeholder-text'
                    }`}
                    onClick={() => setIsTeamOpen(!isTeamopen)}
                    style={{
                      paddingTop: '0.9rem',
                      paddingBottom: '0.9rem',
                      lineHeight: '1.5',
                    }}
                  >
                    {selectedTeam || 'Select Team Size'}
                  </div>
                  {isTeamopen && (
                    <div
                      className='absolute inset-x-0 top-full max-h-28 overflow-y-auto mt-1 bg-white border border-gray-300 rounded-md shadow-lg'
                      style={{ zIndex: 15 }}
                    >
                      {Teams.map((team) => (
                        <div
                          key={team}
                          onClick={() => handleSelectTeam(team)}
                          className='py-2 px-3 cursor-pointer hover:bg-gray-200'
                        >
                          {team}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/*INDUSTRY NAME*/}
              <div className='mb-4 flex justify-start' ref={dropdownRef}>
                <div
                  className={`w-full relative flex items-center custom-input-container industry-dropdown ${
                    isOpen ? 'open' : ''
                  }${industryError ? 'input-error' : ''} ${
                    isPasswordFocused ? 'glass-active' : ''
                  }`}
                >
                  <LiaIndustrySolid
                    className='text-black mr-2 ml-4 mt-1'
                    size={24}
                  />
                  <div
                    className={`block w-full py-2 px-3 custom-bottom-border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base bg-transparent text-black cursor-pointer ${
                      selectedIndustry ? '' : 'placeholder-text'
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{
                      paddingTop: '0.9rem',
                      paddingBottom: '0.9rem',
                      lineHeight: '1.5',
                    }}
                  >
                    {selectedIndustry || 'Select Industry'}
                  </div>
                  {isOpen && (
                    <div
                      className='absolute inset-x-0 top-full max-h-28 overflow-y-auto mt-1 bg-white border border-gray-300 rounded-md shadow-lg'
                      style={{ zIndex: 15, minHeight: '200px' }}
                    >
                      {industries.map((ind) => (
                        <div
                          key={ind}
                          onClick={() => handleSelect(ind)}
                          className='py-2 px-3 cursor-pointer hover:bg-gray-200'
                        >
                          {ind}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* PASSWORD*/}
              <div className='relative mb-4 flex flex-col justify-start'>
                <div
                  className={`flex w-full relative items-center custom-input-container ${
                    passwordError ? 'input-error' : ''
                  }`}
                  style={{ minHeight: '3.5rem' }} // Ensure enough space
                >
                  <MdOutlineLock
                    className='text-black mr-2 ml-4 mt-1'
                    size={24}
                  />
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    id='Password'
                    name='Password'
                    placeholder='Enter Password'
                    value={password}
                    onCopy={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onChange={handlePasswordChange}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                    className='block w-full py-2 px-3 custom-bottom-border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-base bg-transparent text-black'
                    style={{
                      paddingTop: '0.9rem',
                      paddingBottom: '0.9rem',
                      lineHeight: '1.5',
                    }}
                  />
                  {passwordVisible ? (
                    <FaRegEye
                      className='text-black mt-1 mr-4'
                      size={24}
                      onClick={() => setPasswordVisible(false)}
                    />
                  ) : (
                    <PiEyeClosedBold
                      className='text-black mt-1 mr-4'
                      size={24}
                      onClick={() => setPasswordVisible(true)}
                    />
                  )}
                </div>
                {isPasswordFocused && (
                  <div
                    className={`absolute w-half p-2 rounded-md shadow-md z-10 bg-white ${
                      passwordCriteria.length &&
                      passwordCriteria.lowercase &&
                      passwordCriteria.uppercase &&
                      passwordCriteria.number &&
                      passwordCriteria.specialChar
                        ? 'hidden'
                        : ''
                    }`}
                    style={{ top: '-9.8rem', right: '0%', zIndex: 10 }}
                  >
                    <div className='flex flex-col' style={{ zIndex: 10 }}>
                      <div
                        className={`flex items-center mb-2 ${
                          passwordCriteria.length ? 'met' : 'unmet'
                        }`}
                      >
                        {!passwordCriteria.length ? (
                          <RxCrossCircled
                            style={{ color: 'red', fontSize: '16px' }}
                          />
                        ) : (
                          <IoMdCheckmarkCircleOutline
                            style={{ color: 'green', fontSize: '16px' }}
                          />
                        )}

                        <span className='text-sm tabbed'>
                          At least 8 characters
                        </span>
                      </div>
                      <div
                        className={`flex items-center mb-2 ${
                          passwordCriteria.lowercase ? 'met' : 'unmet'
                        }`}
                      >
                        {!passwordCriteria.lowercase ? (
                          <RxCrossCircled
                            style={{ color: 'red', fontSize: '18px' }}
                          />
                        ) : (
                          <IoMdCheckmarkCircleOutline
                            style={{ color: 'green', fontSize: '16px' }}
                          />
                        )}

                        <span className='text-sm tabbed'>
                          One lowercase letter
                        </span>
                      </div>
                      <div
                        className={`flex items-center mb-2 ${
                          passwordCriteria.uppercase ? 'met' : 'unmet'
                        }`}
                      >
                        {!passwordCriteria.uppercase ? (
                          <RxCrossCircled
                            style={{ color: 'red', fontSize: '18px' }}
                          />
                        ) : (
                          <IoMdCheckmarkCircleOutline
                            style={{ color: 'green', fontSize: '16px' }}
                          />
                        )}

                        <span className='text-sm tabbed'>
                          One uppercase letter
                        </span>
                      </div>
                      <div
                        className={`flex items-center mb-2 ${
                          passwordCriteria.number ? 'met' : 'unmet'
                        }`}
                      >
                        {!passwordCriteria.number ? (
                          <RxCrossCircled
                            style={{ color: 'red', fontSize: '18px' }}
                          />
                        ) : (
                          <IoMdCheckmarkCircleOutline
                            style={{ color: 'green', fontSize: '16px' }}
                          />
                        )}
                        <span className='text-sm tabbed'>One number</span>
                      </div>
                      <div
                        className={`flex items-center ${
                          passwordCriteria.specialChar ? 'met' : 'unmet'
                        }`}
                      >
                        {!passwordCriteria.specialChar ? (
                          <RxCrossCircled
                            style={{ color: 'red', fontSize: '18px' }}
                          />
                        ) : (
                          <IoMdCheckmarkCircleOutline
                            style={{ color: 'green', fontSize: '16px' }}
                          />
                        )}
                        <span className='text-sm tabbed'>
                          One special character (@, #, $)
                        </span>
                      </div>
                    </div>
                    <div className='triangle'></div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* CONTINUE / SUBMIT BUTTON */}
          <div
            className={` flex justify-start mt-3`}
            // ${formStep == 2 ? 'mt-0' : 'mt-3'}
          >
            <div className='w-full mt-0'>
              <button
                type='submit'
                disabled={!continues}
                className={`mt-1 block w-full px-3 py-2 text-sm font-medium rounded-md ${
                  continues ? 'custom-button' : 'custom-button1'
                }`}
              >
                {formStep < 2 ? 'Continue' : 'Submit'}
              </button>
            </div>
          </div>

          {/* {formStep == 2 && (
            <div className='mt-1 flex justify-start'>
              <div className='w-full mt-0'>
                <button
                  onClick={handlegoBack}
                  className='back-button mt-1 block w-full px-3 py-2 text-sm font-medium rounded-md'
                >
                  Go Back
                </button>
              </div>
            </div>
          )} */}

          {/* ALREADY ACCOUNT */}
          <div className='mt-2 flex justify-start'>
            <div className='w-full'>
              <p
                className='mt-4 text-left text-black cursor-pointer'
                onClick={() =>
                  (window.location.href = 'https://ocpomni.alendei.io/login')
                }
              >
                Already have an account?{' '}
                <span className='text-green-800'>Login</span>
              </p>
            </div>
          </div>
        </form>
        {redirect && (
          <div className='overlay '>
            <div className='centered-container'>
              <div className='thank-you-container'>
                <h1
                  style={{
                    fontSize: '2rem',
                    // fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  Thank you for Registering!
                </h1>
                <p style={{ paddingTop: '16px', color: 'gray' }}>
                  You will be redirected to the login page soon
                </p>
                <div className='loading-dots pt-5'>
                  <div className='dot'></div>
                  <div className='dot'></div>
                  <div className='dot'></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupForm;
